import {Badge, Card} from "react-bootstrap";
import {ReactComponent as Magnifier} from "../../assets/magnifying-glass.svg";
import {useState} from "react";
import './Product.css';

function Product({item, setModalShow, setModalData}) {
    const [zoomed, setZoomed] = useState(false);

    const handleMouseEnter = () => {
        setZoomed(true);
    };

    const handleMouseLeave = () => {
        setZoomed(false);
    };
    return (
        <div onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onClick={() => {
                 setModalShow(true);
                 setModalData(item);
             }}
             className="goodsCard">
            <Card className="mainCard">
                <Card.Img variant="top" src={require("../../../public/assets/goodsLogo/" + item.logoPath)}
                          className="goodsLogo" alt={item.name + " logo"} loading="lazy"/>
                <Card.Body>
                    <Card.Title className="cardText cardName">
                        {item.name}
                    </Card.Title>
                    <Card.Text className="cardText cardPrice">
                        {
                            item.price ? item.price + " ₽ за КГ" : "Цена по запросу"
                        }
                    </Card.Text>
                </Card.Body>
            </Card>
            {zoomed && (
                <div>
                    <div className="blur"></div>
                    <div className="zoomed-image">
                        <Magnifier className="magnifier"/>
                    </div>
                </div>
            )}
            <Badge
                className="goodsBadge"
                bg="danger"
                as="div"
            >
                ⌀ {item.diameter}
            </Badge>
        </div>
    )
}

export default Product